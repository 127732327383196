import * as React from 'react'
import { Router } from "@reach/router"
import Station from '/src/components/station'
import NotFoundPage from '/src/pages/404'

const StationerRouter = () => {
  return (
    <Router basepath="/stations">
      <Station path="/:id" />
      <NotFoundPage default />
    </Router>
  )
}
export default StationerRouter